<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <h2 class="title-style">
          <v-icon class="icon-style">
            mdi-domain
          </v-icon> Organization Details
        </h2>
      </v-col>
      <v-col
        v-if="isOrganizationDetailsFetched"
        cols="12"
        md="3"
        class="subscription-details"
      >
        <p class="my-p-style">
          Version:-
          <font class="font-my-style">
            Advanced
          </font>
        </p> <p class="my-p-style">
          Status:-
          <font
            v-if="organizationDetails.subscription_status !== null"
            class="my-status-style"
            :color="getColor(organizationDetails.subscription_status)"
          >
            {{ organizationDetails.subscription_status | statusUpdate }}
          </font>
          <font
            v-else
            class="font-my-style"
          >
            N/A
          </font>
        </p>
      </v-col>
    </v-row>
    <v-card
      class="my-base-vcard-style"
    >
      <br>
      <v-card
        class="my-vcard-style"
      >
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="formData.name"
                  label="Organization Name*"
                  hint="Name for your reference - 'DBSystems'."
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="formData.organizationType"
                  menu-props="auto"
                  :items="organizationsList"
                  label="Organization Type*"
                  item-text="id"
                  item-value="value"
                  prepend-icon="mdi-domain"
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <address-form
                  ref="addressForm"
                  @addressEntered="assiginAddress($event)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="formData.phoneNumber"
                  label="Phone-Number"
                  type="number"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="formData.officeTimeZone"
                  menu-props="auto"
                  :items="timeZonesByName"
                  label="Time Zone*"
                  prepend-icon="mdi-clipboard-text-clock-outline"
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="formData.alias"
                  label="Alias"
                  disabled
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="formData.externalId"
                  label="Organization-Id"
                  disabled
                />
              </v-col>
            </v-row>
          </v-form>
          <br>
          <p class="text-sm-left">
            *Indicates required field
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="permissionCheck('user-edit-organization')"
            color="#FF3700"
            @click="submit()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
      <centre-spinner
        :loading="loading"
      />
      <br>
    </v-card>
  </v-container>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import AddressForm from 'src/views/dashboard/component/AddressForm';
  import global from 'src/mixins/global';

  export default {
    name: 'EditOrganization',
    components: {
      'centre-spinner': spinner,
      'address-form': AddressForm,
    },
    filters: {
      firstLetterCapital (val) {
        if (val) {
          return val[0].toUpperCase() + val.slice(1);
        }
      },
      statusUpdate (status) {
        if (status === 'active') {
          return 'Active*';
        } else if (status === 'trial') {
          return 'Trial*';
        } else if (status === 'grace_period') {
          return 'Grace Period';
        } else {
          return 'Expired';
        }
      },
    },
    mixins: [global],
    data () {
      return {
        valid: false,
        loading: false,
        formData: {
          name: '',
          organizationType: '',
          phoneNumber: null,
          officeTimeZone: '',
          alias: null,
          externalId: '',
          address: {},
        },
        organizationsList: [{ id: 'Church', value: 'church' }, { id: 'Shopping Centre', value: 'shoppingCentre' }, { id: 'Construction', value: 'construction' }, { id: 'Restaurants', value: 'restaurants' }, { id: 'Dental', value: 'dental' }],
      };
    },
    computed: {
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      timeZones () {
        return this.$store.getters['userprofile/getTimeZones'];
      },
      timeZonesByName () {
        return Object.keys(this.timeZones).map((key) => ({ text: key, value: this.timeZones[key] }));
      },
      isOrganizationDetailsFetched () {
        if (Object.keys(this.organizationDetails).length > 0) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
      this.loading = true;
      await this.FetchOrganizationDetails();
      this.fillData();
      this.getAllTimeZone();
      this.loading = false;
    },
    methods: {
      fillData () {
        this.formData.name = this.organizationDetails.name;
        this.formData.organizationType = this.organizationDetails.type;
        this.formData.phoneNumber = this.organizationDetails.phone_number;
        this.formData.officeTimeZone = this.organizationDetails.office_timezone;
        this.formData.alias = this.organizationDetails.alias;
        this.formData.externalId = this.organizationDetails.external_id;
        if (this.organizationDetails.address !== null) {
          this.$refs.addressForm.fillData(this.organizationDetails.address);
        }
      },
      async submit () {
        this.$refs.addressForm.getEnteredAdress();
        if (this.$refs.form.validate() === false || Object.keys(this.formData.address).length === 0) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        if (Object.keys(this.formData.address).length > 0) {
          this.loading = true;
          await this.$store.dispatch('userprofile/updateOrganizationDetails', {
            editOrganizationDetails: {
              name: this.formData.name,
              timezone: this.formData.officeTimeZone,
              address: this.formData.address,
              phone_number: this.formData.phoneNumber,
              type: this.formData.organizationType,
            },
          }).then(response => {
            this.$store.dispatch('alert/onAlert', {
              message: 'Organization updated successfully.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.formData.address = {};
            this.loading = false;
            this.FetchOrganizationDetails();
          }).catch(() => {
            this.loading = false;
          });
        }
      },
      assiginAddress (address) {
        this.formData.address = address;
      },
      async FetchOrganizationDetails () {
        this.loading = true;
        await this.$store.dispatch('userprofile/fetchOrganizationDetails');
        this.loading = false;
      },
      getAllTimeZone () {
        this.$store.dispatch('userprofile/fetchTimeZones');
      },
      getColor (status) {
        if (status === 'active') return '#2E7D32';
        else if (status === 'expired') return '#C62828'; else return '#EF6C00';
      },
    },
  };
</script>
<style scoped>
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.title-style {
  color: #37474F;
}
.my-base-vcard-style {
  background-color: #CFD8DC !important;
}
.my-vcard-style {
  margin-right: 30px;
  margin-left: 30px;
  background-color: #ECEFF1;
  border-radius: 25px;
}
.font-my-style {
  font-weight: bold;
  font-size: 17px;
  color: #37474F;
}
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  margin-bottom: 5px;
}
.my-status-style {
  font-weight: bold;
  font-size: 17px;
}
.subscription-details {
  border: 2px solid #CFD8DC;
  border-radius: 15px;
}
</style>
