<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-clipboard-text-clock
      </v-icon> Purchase History
    </h2>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="organizationPurchaseHistory"
        :search="search"
        disable-sort
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.organization_time_slot.digi_board_time_slot.digi_board.external_id }}
            </td>
            <td>
              {{ item.organization_time_slot.digi_board_time_slot.digi_board.location }}
            </td>
            <td>
              {{ item.organization_time_slot.digi_board_time_slot.time_slot.name }}
            </td>
            <td>
              <v-chip
                color="#37474F"
                small
                outlined
                dark
              >
                {{ item.organization_time_slot.digi_board_time_slot.time_slot.duration }} Sec
              </v-chip>
            </td>
            <td
              class="period-style"
            >
              {{ item.organization_time_slot.pricing_period }}
            </td>
            <td>
              {{ item.organization_time_slot.start | convertToLocal }}
            </td>
            <td>
              {{ item.organization_time_slot.end | convertToLocal }}
            </td>
            <td>
              <font
                class="font-my-style"
                :color="getColor(item.organization_time_slot.subscription_status)"
              >
                {{ item.organization_time_slot.subscription_status | statusUpdate }}
              </font>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import global from 'src/mixins/global';

  export default {
    name: 'OrganizationPurchaseHistory',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      statusUpdate (subscriptionStatus) {
        if (subscriptionStatus === 'trial') {
        return 'Trial';
      } else if (subscriptionStatus === 'active') {
        return 'Active*';
      } else if (subscriptionStatus === 'grace_period') {
        return 'Grace Period';
      } else if (subscriptionStatus === 'cancelled') {
        return 'Cancelled';
      } else { return 'Expired'; }
      },
    },
    mixins: [global],
    data () {
      return {
        search: '',
        headers: [
          { text: 'Device Id', align: 'start', value: 'external_id' },
          { text: 'Location', value: 'location' },
          { text: 'Slot Name', value: 'name' },
          { text: 'Duration', value: 'duration' },
          { text: 'Plan Period', value: 'pricing_period' },
          { text: 'Start', value: 'start' },
          { text: 'Ends At', value: 'end' },
          { text: 'Status', value: 'subscription_status' },
        ],
        loading: false,
      };
    },
    computed: {
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      organizationPurchaseHistory () {
        const list = this.$store.getters['userprofile/getOrganizationPurchaseHistory'];
        const sortedList = list.sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        return sortedList;
      },
      },
    async mounted () {
      await this.fetchOrganizationDetails();
      await this.fetchOrganizationPurchaseHistory();
    },
    methods: {
      async fetchOrganizationPurchaseHistory () {
        this.loading = true;
        await this.$store.dispatch('userprofile/fetchOrganizationPurchaseHistory', {
          organizationId: this.organizationDetails.external_id,
        });
        this.loading = false;
      },
      async fetchOrganizationDetails () {
        this.loading = true;
        await this.$store.dispatch('userprofile/fetchOrganizationDetails');
        this.loading = false;
      },
      getColor (active) {
        if (active === 'active') {
            return '#2E7D32';
        } else if (active === 'expired') {
            return '#C62828';
        } else if (active === 'cancelled') {
            return '#C62828';
        } else {
            return '#EF6C00';
        }
      },
    },
  };
</script>
<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.font-my-style {
  font-weight: bold;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.period-style {
  text-transform: capitalize;
}
.currency-style {
  color: #FF3700;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
}
</style>
