<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-clipboard-text-clock
      </v-icon> Payment History
    </h2>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="paymentHistory"
        :search="search"
        disable-sort
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.payment_date | convertToLocal }}
            </td>
            <td>
              <span>BannerEx - Advanced</span>
            </td>
            <td class="period-style">
              {{ item.period }}
            </td>
            <td>
              <v-chip
                color="#37474F"
                small
                outlined
                dark
              >
                {{ item.quantity }}
              </v-chip>
            </td>
            <td>
              <span class="currency-style">{{ item.currency }}</span> {{ item.amount | convertAmount }}
            </td>
            <td>
              {{ item.current_period_end | convertToLocal }}
            </td>
            <td>
              <font
                :color="statusColor(item.stripe_status)"
                class="font-my-style"
              >
                {{ item.stripe_status | statusUpdate }}
              </font>
            </td>
            <td>
              {{ item.payment_by }}
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="downloadInvoice(item.id, item.mode)"
              >
                <v-icon>mdi-tray-arrow-down</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import global from 'src/mixins/global';
  import Constants from 'src/constants';

  export default {
    name: 'SubscriptionsHistory',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      statusUpdate (subscriptionStatus) {
        if (subscriptionStatus === 'active' || subscriptionStatus === 'succeeded') {
          return 'Completed*';
        } else {
          return 'N/A';
        }
      },
      convertAmount (amount) {
        return amount / 100;
      },
    },
    mixins: [global],
    data () {
      return {
        search: '',
        headers: [
          { text: 'Payment Date', align: 'start', value: 'payment_date' },
          { text: 'Version', value: 'version' },
          { text: 'Plan Period', value: 'period' },
          { text: 'Quantity', value: 'quantity' },
          { text: 'Amount (Tax Incl.)', value: 'amount' },
          { text: 'Ends At', value: 'current_period_end' },
          { text: 'Status', value: 'stripe_status' },
          { text: 'Payment By', value: 'payment_by' },
          { text: 'Invoice', value: 'id' },
        ],
        loading: false,
      };
    },
    computed: {
      subscriptionsHistory () {
        return this.$store.getters['payments/getSubscriptionsHistory'];
      },
      invoiceUrls () {
        return this.$store.getters['payments/getInvoiceDownloadUrl'];
      },
      oneTimePaymentHistory () {
        return this.$store.getters['payments/getOneTimePaymentHistory'];
      },
      paymentHistory () {
        const history = [...this.subscriptionsHistory, ...this.oneTimePaymentHistory];
        const sortedList = history.sort((a, b) => {
          return new Date(b.payment_date) - new Date(a.payment_date);
        });
        return sortedList;
      },
    },
    async mounted () {
     await this.fetchPaymentHistory();
    },
    methods: {
      async fetchPaymentHistory () {
        this.loading = true;
        await this.$store.dispatch('payments/fetchSubscriptionsHistory');
        await this.$store.dispatch('payments/fetchOneTimePaymentHistory');
        this.loading = false;
      },
      async downloadInvoice (id, mode) {
        if (this.permissionCheck('download-invoice') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.loading = true;
        await this.$store.dispatch('payments/fetchInvoiceDownloadUrl', {
          subscriptionId: id,
          purchaseMode: mode,
        }).then(response => {
          this.loading = false;
          window.open(this.invoiceUrls.download_url, '_blank');
        }).catch(() => {
          this.loading = false;
        });
      },
      statusColor (status) {
        if (status === 'active' || status === 'succeeded') {
          return '#2E7D32';
        } else {
          return '#C62828';
        }
      },
    },
  };
</script>
<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.font-my-style {
  font-weight: bold;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.period-style {
  text-transform: capitalize;
}
.currency-style {
  color: #FF3700;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
}
</style>
